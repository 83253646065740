html {
	font-family: sans-serif; // 1
	text-size-adjust: 100%; // 2
	-webkit-text-size-adjust: 100%; // 2
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
	height: 100%;
	margin: 0px;
	padding: 0px;
	font-size: $root-font-size !important;
	font-weight: $font-weight-base;
	font-family: $font-family-sans-serif;

	// Tablet mode
	@include media-breakpoint-down(lg) {
		font-size: $root-font-size-lg !important;
	}

	// Mobile mode
	@include media-breakpoint-down(md) {
		font-size: $root-font-size-md !important;
	}

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	a:hover,
	a:active,
	a:focus {
		text-decoration: none !important;
	}
}

body {
	display: flex;
	flex-direction: column;
	color: $body-color;
}

// Angular integration
router-outlet {
	display: none;
}

canvas {
	user-select: none;
}
